import React, { Component } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import riskSlide from '../../assets/img/slide-3.jpg';
import opsSlide from '../../assets/img/slide-2.jpg';
import emerSlide from '../../assets/img/slide-1.jpg';

export default class ServiceSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slides: [
        {
          img: riskSlide,
          link: "risk-management"
        },
        {
          img: opsSlide,
          link: "operations"
        },
        {
          img: emerSlide,
          link: "acquisitions"
        }
      ]
    }
  }
  render() {
    return (
      <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={50}
      interval={6000}
      totalSlides={this.state.slides.length}
      visibleSlides={1}
      isPlaying={true}
      touchEnabled={false}
    >
      <Slider>
        {
          this.state.slides.map((slide, i) => {
            return <Slide onClick={() => this.props.handleClick(slide.link)} index={i} className="xyz" >
              <img alt=" "  src={slide.img} style={{
                justifySelf: 'center',
                alignSelf: 'center'
              }} />
            </Slide>
          })
        }
      </Slider>
    </CarouselProvider>

    )
  }
}
