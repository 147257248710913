import React from 'react';
import axios from '../axios';
import ReactMarkdown from 'react-markdown';
import Layout from '../Layout';

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      content: ""
    }
  }
  componentDidMount(){
    this.getContent();
  }
  async getContent(){
    const { data } = await axios.get(`contentblocks?key=ourpeople-content`);
    this.setState({
      content: data[0].Value
    });
  }
  render(){
    return <Layout relativeHeader>
      <div className="page-image-banner" style={{backgroundImage:"url(https://naissance.gec.group//uploads/ab53b46ba13442f5b9f43e4570854934.jpg)"}}>
        <div className="header-overlay">
          <h1 className="mega-heading">Our People</h1>
          </div>
      </div>
      <div className="content-layout padding-80">
        <div className="article">
          <ReactMarkdown html={true} source={this.state.content}/>
        </div>
      </div>
    </Layout>
  }
}