import React from 'react';
import axios from '../axios';
import { NavLink } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import Layout from '../Layout';
import ServiceSlider from '../components/ServiceSlider';

import { BreakerSquares } from '../components';

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      posts: [],
      caseStudies: [],
      content: ""
    }
  }
  componentDidMount(){
    // this.getPosts();
    this.getCaseStudies();
    this.getContent();
  }
  async getContent(){
    const { data } = await axios.get(`contentblocks?key=home-content`);
    this.setState({
      content: data.length ? data[0].Value : '...'
    });
  }
  async getPosts(){
    const { data } = await axios.get("posts?_limit=6");
    this.setState({
      posts: data
    });
  }
  async getCaseStudies(){
    const { data } = await axios.get("casestudies?_limit=6");
    this.setState({
      caseStudies: data
    });
  }
  render(){
    return <Layout >
      <div className="home-banner">
          <h1 className="mega-heading">Experts in Risk, Modelling, Conduct, Compliance, 
Treasury and Operations.</h1>
          <div className="home-banner-service-selector">
            <h3>I'm looking for support with</h3>
            <select defaultValue="select" onChange={e => this.props.history.push(`/our-services/${e.currentTarget.value}`)}>
              <option disabled value="select">...</option>
              <option value="risk-management">Risk Management</option>
              <option value="treasury-and-liquidity">Treasury &amp; Liquidity</option>
              <option value="operations">Operations</option>
              <option value="system-implementation">System Implementation</option>
              <option value="financial-crime">Financial Crime</option>
              <option value="acquisitions">Acquisitions</option>
              <option value="corporate-restructuring">Corporate Restructuring</option>
            </select>
          </div>
      </div>
      <div className="content-layout padding-left-80 padding-right-80" style={{textAlign:"center"}}>
        <div/>
        <div className="col-1" style={{justifyItems:"center"}}>
            <BreakerSquares />
            <h2 className="margin-top-20 mega-heading" style={{maxWidth: '900px'}}>We are a leading independent consulting firm based in London</h2>
            <div className="margin-top-60">
            <div className="article">
          <ReactMarkdown html={true} source={this.state.content}/>
        </div>
        </div>
        {/* to be an image slider */}
        <div className="margin-top-80 service-slider">
          <ServiceSlider handleClick={link => this.props.history.push(`our-services/${link}`)}/>
        </div>
        {/* <h3 className="margin-top-20 mega-heading margin-top-90 margin-bottom-60" style={{maxWidth: '900px', fontSize: 36}}>Our Latest News</h3>
        {
          this.state.posts.length ? <div className="col-4 gap-30"> 
          {this.state.posts.map(x => <NavLink className="post-preview" to={`/news/${x["Slug"]}`}>
            <div>
              <img alt="Banner" src={`https://naissance.gec.group${x["Header Image"].url}`}/>
              <div>
                <h4>{x["Title"]}<br/><small>
                  {x["Sub Title"]}  
                </small></h4>
              </div>
            </div>
          </NavLink>)}
          </div> : <p>No posts found.</p>
        } */}
        <h3 className="margin-top-90 mega-heading margin-top-90 margin-bottom-60" style={{maxWidth: '900px', fontSize: 36}}>Case Studies</h3>
        {
          this.state.caseStudies.length ? <div className="col-4 gap-30"> 
          {this.state.caseStudies.map(x => <NavLink className="post-preview" to={`/case-studies/${x["slug"]}`}>
            <div>
              <div style={{backgroundImage: `url(https://naissance.gec.group${x["header_image"].url})`}}/>
              
              <div>
                <h4><small>
                  {x["Title"]}  
                </small></h4>
              </div>
            </div>
          </NavLink>)}
          </div> : <p>No case studies found.</p>
        }
        </div>
        {/* <div/> */}
      </div>
    </Layout>
  }
}