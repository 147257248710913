import React from 'react';
import Layout from '../Layout';
import axios from '../axios';
import ReactMarkdown from 'react-markdown';

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      title: "...",
      subTitle: "...",
      headerImage: "",
      content: ""
    }
  }
  componentDidMount(){
    this.getPost();
  }
  async getPost(){
    const { data } = await axios.get(`posts/?slug=${this.props.match.params.slug}`);
    console.log(data);
    this.setState({
      title: data[0]["Title"],
      subTitle: data[0]["Sub Title"],
      headerImage: `https://naissance.gec.group${data[0]["Header Image"].url}`,
      content: data[0]["Content"]
    });
  }
  render(){
    return <Layout relativeHeader>
      <div className="page-image-banner" style={{backgroundImage:`url(${this.state.headerImage})`}}>
        <div className="header-overlay">
          <h1 className="mega-heading">{this.state.title}</h1>
          <h2 className="sub-heading margin-top-10">{this.state.subTitle}</h2>
          </div>
      </div>
      <div className="content-layout padding-80">
        <div className="article">
          <ReactMarkdown html={true} source={this.state.content}/>
        </div>
      </div>
    </Layout>
  }
}