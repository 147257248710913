import React from 'react';

import Layout from '../Layout';
import { BreakerSquares } from '../components';

export default class extends React.Component {
  render(){
    return <Layout relativeHeader>
       <div className="page-image-banner" style={{backgroundImage:"url(https://i.imgur.com/0iwjHpr.jpg)"}}>
        <div className="header-overlay">
          <h1 className="mega-heading">Support</h1>
          <h2 className="sub-heading margin-top-10">Experienced contracted individuals</h2>
          </div> </div>
      <div className="content-layout padding-left-80 padding-right-80">
        <div/>
        <div>
            <BreakerSquares />
            <h2 className="margin-top-20 mega-heading" style={{maxWidth: '900px'}}>We provide ongoing support</h2>
            <div className="margin-top-60">
          <p>
          Naissance helps clients respond effectively to their commercial and organisational challenges through our network of skilled individuals. 
          <br/><br/>
We provide experienced professionals on a contractual basis to manage projects for businesses. We support clients with everyday business assignments, including modelling, conduct, compliance, remediation, integration, project management, liquidity, treasury, market risk, conduct risk and operations. 
<br/><br/>
All of Naissance's hand-picked professionals have completed a rigorous assessment and referencing process to ensure that our clients are benefitting from leading skills in the market. 
<br/><br/>
If you are seeking interim or executive support, contact us for an initial discussion.         </p>
        </div>
        </div>
        <div/>
      </div>
    </Layout>
  }
}