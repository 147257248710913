import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

import { Home, ContactUs, OurPeople, NotFound, OurServices, Support, PrivacyPolicy, About, News, Post, CmsPage, Search, Careers, Login, Career, CaseStudy, Rbfi } from './views';

const Routes = () => <ScrollToTop><Switch>
  <Route exact path="/" component={Home}/>
  <Route exact path="/clients/login" component={Login}/>
  <Route exact path="/about" component={About}/>
  <Route exact path="/our-people" component={OurPeople}/>
  <Route exact path="/news" component={News}/>
  <Route exact path="/news/:slug" component={Post}/>
  <Route exact path="/our-services" component={OurServices}/>
  <Route exact path="/our-services/restructuring-banks-financial-institutions" component={Rbfi}/>
  <Route exact path="/our-services/:slug" component={CmsPage}/>
  <Route exact path="/case-studies/:slug" component={CaseStudy}/>
  <Route exact path="/support" component={Support}/>
  <Route exact path="/search" component={Search}/>
  <Route exact path="/contact-us" component={ContactUs}/>
  <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
  <Route exact path="/careers" component={Careers}/>
  <Route exact path="/careers/:id" component={Career}/>
  <Route exact path="/legal/:slug" component={CmsPage} />
  <Route exact path="/general/:slug" component={CmsPage} />
  <Route exact path="/not-found" component={NotFound}/>
  <Route render={() => <Redirect to="/" />} />
</Switch></ScrollToTop>

export default Routes;