import React from 'react';
import { NavLink } from 'react-router-dom';

import Logo from '../../assets/img/logo.svg';
import ClientSlider from './PrevClientSlider';

export default class extends React.Component {
  render(){
    return <div>
      <h3 className="margin-bottom-20 margin-top-60" style={{fontSize: 36, textAlign: "center"}}>Previous Clients</h3>
      {/* <img alt="Our clients list banner" src={Clients} style={{width:"100%",paddingLeft:50,paddingRight:50,marginBottom:80,marginTop:50}}/> */}
      <ClientSlider />
      <div className="footer-contact-bar col-2 padding-60 grid-center-center">
        <h2>
          Get in contact with Naissance
          <small style={{opacity:.5}}><br/>
            info@naissance.co.uk
          </small>
        </h2>
        <div style={{justifySelf: "end"}}>
        <a href={"mailto:info@naissance.co.uk"} className="margin-right-15 alt-btn margin-bottom-10">Email us</a>
          <NavLink to={"/contact-us"} className="btn">View contact details</NavLink>
        </div>
      </div>
      <div className="footer padding-top-60 padding-left-60 padding-right-60 padding-bottom-30">
      <img className="logo" src={Logo} alt="Naissance"/>
      <div className="col-3 padding-top-40 padding-bottom-40">
      <ul>
          <li>
            <NavLink activeClassName="active" exact to="/">Home</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" exact to="/about">About</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/our-services">Our Services</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/news">News</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/support">Support</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" to="/contact-us">Contact Us</NavLink>
          </li>
        </ul>
        <ul>
        <li>
            <NavLink activeClassName="active" exact to="/careers">Careers</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" exact to="/general/philanthropy">Philanthropy</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" exact to="/legal/modern-slavery-statement">Modern Slavery Statement</NavLink>
          </li>
          <li>
            <NavLink activeClassName="active" exact to="/privacy-policy">Privacy Policy</NavLink>
          </li>
        </ul>
      </div>
      <p className="footer-note margin-top-40">
        <a name="LinkedIn" href="https://www.linkedin.com/company/naissance-ltd" className="margin-right-15" rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin"></i></a>
        <a name="LinkedIn" href="https://www.facebook.com/naissanceltd" className="margin-right-15" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook"></i></a>
        <a name="LinkedIn" href="https://www.twitter.com/naissanceltd" className="margin-right-15" rel="noopener noreferrer" target="_blank"><i className="fab fa-twitter"></i></a>
        <br/>
        <span style={{fontSize:14}}>&copy; Copyright {new Date().getFullYear()} Naissance Holdings, All rights reserved.</span></p>
    </div>
    </div>
  }
}