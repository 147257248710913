import React from "react";

import Layout from "../Layout";
import { BreakerSquares } from "../components";

export default class extends React.Component {
  render() {
    return (
      <Layout relativeHeader>
        <div className="content-layout padding-left-80 padding-right-80 padding-top-80">
          <div>
            <BreakerSquares />
            <h2
              className="margin-top-20 mega-heading"
              style={{ maxWidth: "900px" }}
            >
              Privacy &amp; Cookies
            </h2>
            <div className="margin-top-60">
              <h3 className="colour-cta">Data Collection</h3>
              <p>
                We may collect and use the following kinds of personal data:
              </p>
              <ul>
                <li>
                  Generic information about your computer and about your visits
                  to and use of this website, such as your IP address,
                  geographical
                  <br />
                  location, browser type, referral source, length of visit and
                  number of page views. This information is gathered in order to
                  understand
                  <br />
                  how our web site is being used, and cannot be associated with
                  you personally.
                </li>
                <li>
                  Information that you provide to us for the purpose of
                  registering your interest with us including your name,
                  telephone number and email address.
                </li>
                <li>
                  Information that you provide to us for the purpose of
                  subscribing to our newsletter such as your name and email
                  address.
                </li>
                <li>
                  Any other information that you choose to send to us such as
                  information in the message box of our input form.
                </li>
              </ul><br />
              <h3 className="colour-cta">Cookies</h3>
              <p>We do not use cookies on this web site.</p><br />
              <h3 className="colour-cta">Your personal data</h3>
              <p>
                Personal data submitted on this website will be used for the
                purposes specified in this privacy policy or in relevant parts
                of the website.
              </p>
              <p>We may use your personal information to:</p>
              <ul>
                <li>supply services you have requested from us</li>
                <li>send non-marketing commercial communications</li>
                <li>
                  send email notifications which you have specifically requested
                </li>
                <li>send our newsletter</li>
                <li>deal with enquiries made by you</li>
              </ul><br />
              <h3 className="colour-cta">Disclosures</h3>
              <p>
                We will not, under any circumstances, provide your personal
                information to any third parties for the purpose of direct
                marketing.
              </p><br />
              <h3 className="colour-cta">Security of your personal data</h3>
              <p>
                We will take reasonable technical and organisational precautions
                to prevent the loss, misuse or alteration of your personal
                information.
              </p><br />
              <h3 className="colour-cta">Updating information</h3>
              <p>
                Please let us know if the personal information which we hold
                about you needs to be corrected or updated.
              </p><br />
              <h3 className="colour-cta">Contact Details</h3>
              <p>
                If you have any questions about this privacy policy or how we
                deal with your personal data you can contact us.
                <br />
                by email at{" "}
                <a href="mailto:info@naissance.co.uk">info@naissance.co.uk</a>
              </p>
            </div>
          </div>
          <div />
        </div>
      </Layout>
    );
  }
}
