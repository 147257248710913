import React from "react";
import ReactMarkdown from "react-markdown";
import { NavLink } from "react-router-dom";
import axios from "../axios";
import Layout from "../Layout";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      slug: "",
      title: "...",
      content: "",
      backgroundImage: null,
      posts: []
    };
  }
  componentDidMount() {
    if (this.props.match.params.slug !== this.state.slug) {
      this.getPage();
      this.getPosts();
    }
  }
  componentDidUpdate() {
    this.componentDidMount();
  }
  async getPage() {
    this.setState({ slug: this.props.match.params.slug });
    const { data } = await axios.get(
      `casestudies/?slug=${this.props.match.params.slug}`
    );
    if (data.length) {
      this.setState({
        title: data[0]["Title"],
        content: data[0]["content"],
        backgroundImage: data[0]["header_image"]
          ? `https://naissance.gec.group/${data[0]["header_image"].url}`
          : "https://i.imgur.com/r4OKqpB.jpg"
      });
    } else {
      this.props.history.push("/");
    }
  }
  async getPosts() {
    const { data } = await axios.get(
      `casestudies/?slug_ne=${this.props.match.params.slug}`
    );
    console.log(data);
    if (data.length) {
      this.setState({
        posts: data.map(x => ({
          title: x["Title"],
          content: x["content"],
          slug: x["slug"],
          backgroundImage: x["header_image"]
            ? `https://naissance.gec.group/${x["header_image"].url}`
            : "https://i.imgur.com/r4OKqpB.jpg"
        }))
      });
    }
  }
  render() {
    return (
      <Layout relativeHeader>
        <div
          className="page-image-banner"
          style={{ backgroundImage: `url(${this.state.backgroundImage})` }}
        >
          <div className="header-overlay">
            <h1 className="mega-heading">{this.state.title}</h1>
          </div>
        </div>
        <div className="content-layout padding-80">
          <div className="article">
            <ReactMarkdown html={true} source={this.state.content} />
          </div>
          
        {
          this.state.posts.length ? <div><h3 className="margin-top-90 mega-heading margin-top-90 margin-bottom-60" style={{textAlign: "center", fontSize: 36}}>More Case Studies</h3><div className="col-3 gap-30"> 
          {this.state.posts.map(x => <NavLink className="post-preview" to={`/case-studies/${x.slug}`}>
            <div>
              <div style={{backgroundImage: `url(${x.backgroundImage})`}}/>
              
              <div>
                <h4><small>
                  {x.title}  
                </small></h4>
              </div>
            </div>
          </NavLink>)}
          </div></div> : null
        }
        </div>
      </Layout>
    );
  }
}
