import React from "react";
import { NavLink } from 'react-router-dom';

import Logo from "../../assets/img/logo-white.svg";
import ColourLogo from "../../assets/img/logo.svg";

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      showMenu: false,
      search: ''
    }
  }
  render() {
    return (
      <div className={`header ${this.props.relative ? 'header-relative' : ''}`}>
        <NavLink exact to="/"><img className="logo" src={this.props.relative ? ColourLogo : Logo} alt="Naissance"/></NavLink>
        <div className="header-mobile-trigger" onClick={() => this.setState({showMenu:!this.state.showMenu})}>
          <i className={`fal ${this.state.showMenu ? 'fa-times' : 'fa-bars'}`}></i>
        </div>
        <ul className={`header-navigation ${this.state.showMenu ? 'mobile-show' : 'mobile-hide'}`}>
            <li>
              <NavLink activeClassName="active" exact to="/">Home</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/about">About</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/our-people">Our People</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/our-services">Services</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/news">News</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/support">Support</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/contact-us">Contact</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/clients/login"><i className="far fa-lock margin-right-10"></i>Login</NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/search"><i className={`far fa-search ${this.state.showMenu ? "margin-right-10" : ""}`}></i>{this.state.showMenu ? "Search" : ""}</NavLink>
            </li>
          {/* <div className="search" style={{
            background: 'rgba(255, 255, 255, 0.1)'
          }}>
            <li style={{
              alignSelf: 'center'
            }}>
              <input style={{
                  background: 'none',
                  outline: 'none',
                  border: 0,
                  padding: 0
                }}
                value={this.state.search}
                type="text"
                placeholder="Search..."
                onChange={e => this.setState({ search: e.target.value })}
              />
            </li>
            <li>
              <NavLink to={`/search/${encodeURIComponent(this.state.search)}`}><span className="fal fa-search"></span></NavLink>
            </li>
          </div> */}
        </ul>
      </div>
    );
  }
}
