import React from 'react';

export default class extends React.Component {
  render(){
    return <div className="breaker-squares">
      <div/>
      <div/>
      <div/>
    </div>
  }
}