import React from 'react';
import axios from '../axios';
import ReactMarkdown from 'react-markdown';
import Layout from '../Layout';

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      content: ""
    }
  }
  componentDidMount(){
    this.getContent();
  }
  async getContent(){
    const { data } = await axios.get(`contentblocks?key=about-content`);
    this.setState({
      content: data[0].Value
    });
  }
  render(){
    return <Layout relativeHeader>
      <div className="page-image-banner" style={{backgroundImage:"url(https://i.imgur.com/pOuT6Ix.jpg)"}}>
        <div>
          <h1 className="mega-heading">About Naissance</h1>
          <h2 className="sub-heading margin-top-10">Learn more about what we do.</h2>
          </div>
      </div>
      <div className="content-layout padding-80">
        <div className="article">
          <ReactMarkdown html={true} source={this.state.content}/>
        </div>
      </div>
    </Layout>
  }
}