import React from "react";
import ReactMarkdown from "react-markdown";
import { NavLink } from 'react-router-dom';
import axios from "../axios";
import Layout from "../Layout";
import Fact from "../components/Fact";

export default class extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <Layout relativeHeader>
        <div className="sub-nav">
          <NavLink activeClassName="active" to="/our-services/risk-management">Risk</NavLink>

          <NavLink activeClassName="active" to="/our-services/treasury-and-liquidity">Treasury and Liquidity</NavLink>

          <NavLink activeClassName="active" to="/our-services/operations">Operations</NavLink>

          <NavLink activeClassName="active" to="/our-services/system-implementation">System Implementation</NavLink>

          <NavLink activeClassName="active" to="/our-services/financial-crime">Financial Crime</NavLink>

          <NavLink activeClassName="active" to="/our-services/acquisitions">Acquisitions</NavLink>

          <NavLink activeClassName="active" to="/our-services/corporate-restructuring">Corporate Restructuring</NavLink>

          <NavLink activeClassName="active" to="/our-services/restructuring-banks-financial-institutions">Restructuring Financial Institutions</NavLink>

        </div>
        <div className="page-image-banner" style={{ backgroundImage: `url(https://i.imgur.com/r4OKqpB.jpg)` }}>
          <div className="header-overlay">
            <h1 className="mega-heading">Restructuring of Banks and Financial Institutions</h1>
          </div>
        </div>
        <div className="content-layout padding-80">
          <div className="article">
            <p>
              In the fast-paced world of restructuring, Naissance professionals are here to provide solutions for banks and financial institutions, strengthening and refocusing them to manage risks and to take advantage of opportunities that may present themselves.
              <br /><br />
              These are some of the services we offer:
              <br /><br />
              <div className="col-1 gap-30">
              <Fact title="Planning and Resilience" description={<p>
                  Are you prepared for the challenges of tomorrow?

Prudent financial planning can prevent any unnecessary surprises and can strengthen your fundamentals.
                </p>} body={<>
                  <p>In today&rsquo;s increasingly inter-linked world, effective planning and resilience strategies can give your firm a competitive edge and prevent unnecessary risk.</p>
<p>&nbsp;</p>
<p>Naissance professionals have many years of experience in this field ready to be put at your disposal.&nbsp; Some of the services offered include:</p>
<p>&nbsp;</p>
<ul>
<li>Business impact analysis.</li>
<li>Operational resilience planning.</li>
<li>Organisational resilience planning.</li>
<li>Financial planning.</li>
<li>Review of the balance sheet in order to manage gearing and the debt profile.</li>
<li>Review of IT systems in order to ensure that your institution is able to manage hybrid-working in an effective and secure manner.</li>
<li>Triage of business lines, departments, etc.</li>
<li>Building Internal Credit Grading Models in order to understand credit risks and expected losses at a granular level and, through the observation of grade migrations, achieve an early warning of impending credit strains.</li>
</ul>
                </>} />
                <Fact title="Debt Restructuring" description={<p>
                  Does your debt need optimising?

Restructuring your debt could provide your institution with the flexibility it needs to thrive.
                </p>} body={<>
                  <p>Naissance has deep experience in advising clients on the overall size, type and profile of their debt holdings. We can offer insights and initiatives to help clients achieve optimal, sustainable gearing in line with market best practice. The services that we offer include:</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<ul>
<li>Review of the debt profile to ensure that an optimal level of gearing and a suitable blend of maturities, so as to avoid concentrations in refinancing dates.&nbsp;</li>
</ul>
<ul>
<li>Review of current debt and funding rates to ensure that best possible market rates are being achieved.</li>
</ul>
<ul>
<li>Management of debt through the refinancing or restructuring of borrowings.</li>
</ul>
<ul>
<li>Advice on the diversification of funding and debt sources in order to avoid over-concentrations and to leverage market opportunities.</li>
</ul>
<ul>
<li>Financial forecasting and stress testing in order to illustrate the benefits and risks of current and proposed debt structures over time.</li>
</ul>
<ul>
<li>Ensuring that any debt that is relied on as a form of capital can be fully used for that purpose.</li>
</ul>
                </>} />
                <Fact title="Strategic Review" description={<p>
                  Is your organization operating as efficiently as it can and maximizing its potential?

Naissance can help you to streamIine your organization, optimize your opportunities and achieve your goals, whilst bolstering your resilience.
                </p>} body={<>
                  <p>Naissance can undertake a strategic review of your business, so as to identify potential opportunities, from the divestment of non-core activities to the acquisitions of synergistic third-party units. Such a review would also include an assessment of the existing in-house processes and corporate structure with a view to optimising the business&rsquo;s potential.&nbsp; The strategic review would conclude with the provision of a clear set of recommendations and a roadmap for the accomplishment of the proposed refinements.</p>
<p>&nbsp;</p>
<p>Following a strategic review, Naissance will be able to assist its clients in:</p>
<p>&nbsp;</p>
<ul>
<li>Identifying and rectifying inefficiencies in business processes (Business Process Re-engineering).</li>
<li>Achieving the buy-in of the management-team, the staff and other stakeholders.</li>
<li>Identifying value-creating opportunities within the business.</li>
<li>Assisting with diversification of the business.</li>
<li>Advising on initiatives to further the business&rsquo;s competitive differentiation in the market.</li>
<li>Streamlining of staff.</li>
<li>Triage of departments and functions.</li>
<li>Order-book management.</li>
<li>Supply chain management.</li>
<li>Cash-flow management.</li>
<li>Liquidity management.</li>
<li>Creation and maintenance of a Data Room to a best practice standard.</li>
<li>When required, supporting the process of selling individual business units or the entire business once a trade buyer has been found.</li>
</ul>
                </>} />
                <Fact title="Recovery and Resolution Planning" description={<p>
                  Do you have a Recovery &amp; Resolution Plan that is fit for purpose and meets best practice?

Naissance can capably assist you with market leading solutions, as well as well-tried, practical and timely actions if the plan is needed to be enacted.
                </p>} body={<>
                  <p>Every regulated Financial Institution is required to have a credible Recovery and Resolution Plan (RRP).</p>
<p>&nbsp;</p>
<p>Naissance professionals can guide you through this ever-changing regulatory landscape to ensure that you have a best-practice RRP that is appropriate and workable for your specific institution.&nbsp; The services that we offer include:</p>
<p>&nbsp;</p>
<ul>
<li>Building stress scenarios in order to understand the risks that the institution faces and to test the completeness, effectiveness and feasibility of the RRP remedial measures and the resolution strategy.</li>
<li>Identifying recovery options, together with an appreciation of their monetary impact, feasibility, timeliness and franchise impact.</li>
<li>Advising on the creation and maintenance of an effective Data Room.</li>
<li>Advising on a suite of resolution tools such as the sale of business lines, asset separation, the creation and operation of a bridge institution and a bail-in.</li>
<li>Offering an effective wind-down plan, enabling your institution to cease regulated activities with minimal adverse effects on clients and markets.</li>
<li>Ensuring that best practice and proper governance is applied with regards to the creation, maintenance and execution of the RRP.</li>
<li>Embedding the RRP into your institution&rsquo;s wider risk management framework.</li>
</ul>
                </>} />
              </div>
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}
