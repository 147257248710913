import React from "react";
import axios from "../axios";
import Layout from "../Layout";
import { BreakerSquares } from "../components";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      message: "",
      sent: false
    };
  }
  submitMessage = async e => {
    e.preventDefault();
    await axios.post(`messages`, {
      Name: this.state.name,
      Email: this.state.email,
      Message: this.state.message
    });
    this.setState({
      sent: true
    });
  };
  render() {
    return (
      <Layout relativeHeader>
        <div className="page-image-banner" style={{backgroundImage:`url(https://i.imgur.com/pUkdAHL.jpg)`}}>
          <div className="header-overlay">
          <h1 className="mega-heading">Contact Us</h1>
          <h2 className="sub-heading margin-top-10">How to contact us.</h2>
          </div>
        </div>
        <div className="content-layout padding-left-80 padding-right-80">
          <div />
          <div>
            <BreakerSquares />
            <h2 className="margin-top-20" style={{ maxWidth: "900px" }}>
              Get in contact with Naissance
            </h2>
            <div className="margin-top-60 margin-bottom-60 grid col-2">
              <p>
                <b>Naissance (UK) Limited</b>
                <br /><br />
                20 Hornton Street
                <br />
                London
                <br />
                W8 4NR
                <br />
                <br />
                Baird House <br />
                Scotswood Road
                <br /> Newcastle upon Tyne
                <br /> NE4 7DF
                <br />
                <br />
                Email:{" "}
                <a href="mailto:info@naissance.co.uk">info@naissance.co.uk</a>
                <br />
                <br />
                <br />
                <b>Registered Office</b>
                <br />
                20 Hornton Street
                <br />
                London
                <br />
                W8 4NR
              </p>
              <div>
              
              <iframe
                title="Naissance Map"
                src="https://maps.google.com/maps?q=20%20Hornton%20Street%20London%20W8%204NR&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="600"
                height="300"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
              <iframe
                title="Naissance Map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d884.492739959283!2d-1.6211081883661025!3d54.97227578700961!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe448a2cb6d9116f0!2sThe+Core!5e0!3m2!1sen!2suk!4v1566464160264!5m2!1sen!2suk"
                width="600"
                height="300"
                frameborder="0"
                style={{ border: 0, marginTop: 20 }}
                allowfullscreen
              ></iframe>
              </div>
            </div>
            <h2 className="margin-bottom-40" style={{ maxWidth: "900px" }}>
              Leave a message
            </h2>
            {this.state.sent ? (
              <p>Thank you for the message. We will be in contact soon.</p>
            ) : (
              <form
                className="col-1"
                style={{ maxWidth: "500px" }}
                onSubmit={this.submitMessage}
              >
                <label className="margin-bottom-10">Name</label>
                <input
                  type="text"
                  required
                  onChange={e => this.setState({ name: e.currentTarget.value })}
                />
                <br />
                <label className="margin-bottom-10">Email</label>
                <input
                  type="email"
                  required
                  onChange={e =>
                    this.setState({ email: e.currentTarget.value })
                  }
                />
                <br />
                <label className="margin-bottom-10">Message</label>
                <textarea
                  required
                  onChange={e =>
                    this.setState({ message: e.currentTarget.value })
                  }
                />
                <br />
                <br />
                <button className="btn">Send message</button>
              </form>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}
