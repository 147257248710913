import React from 'react';

import { Header, Footer } from './components';

export default class extends React.Component {
  render(){
    return <>
      <Header relative={this.props.relativeHeader}/>
        <div className="content-container">
          {this.props.children}
        </div>
      <Footer/>
    </>
  }
}
