import React from 'react';

import Layout from '../Layout';

export default class extends React.Component {
  render(){
    return <Layout relativeHeader>
       <div className="padding-60">
        <h1>Not Found</h1>
      </div>
    </Layout>
  }
}