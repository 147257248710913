import React, { useState } from "react";

function Fact(props) {
    const [open, setOpen] = useState(false);
    return <div className="ui-fact">
    <div>
      <h2>{props.title}</h2>
      <p>{props.description}</p>
      <button onClick={() => setOpen(!open)} className="btn">Read {open ? "less" : "more"}</button>
    </div>
    {open ? <div className="ui-fact-details">
    {props.body}
    </div> : null}
  </div>;
}

export default Fact;
