import React from 'react';
import Layout from '../Layout';
import axios from '../axios';
import { NavLink } from 'react-router-dom';

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      posts: []
    }
  }
  componentDidMount(){
    this.getPosts();
  }
  async getPosts(){
    const { data } = await axios.get("posts");
    this.setState({
      posts: data
    });
  }
  render(){
    return <Layout relativeHeader>
      <div className="page-image-banner" style={{backgroundImage:"url(https://i.imgur.com/RtSANc3.jpg)"}}>
        <div className="header-overlay">
          <h1 className="mega-heading">Our Latest News</h1>
          <h2 className="sub-heading margin-top-10">Keep up to date with Naissance.</h2>
          </div></div>
      <div className="content-layout padding-80">
      {
          this.state.posts.length ? <div className="col-3 gap-30"> 
          {this.state.posts.map(x => <NavLink className="post-preview" to={`/news/${x["Slug"]}`}>
            <div>
              <div style={{backgroundImage: `url(https://naissance.gec.group${x["Header Image"].url})`}}/>
              <div>
                <h4>{x["Title"]}<br/><small>
                  {x["Sub Title"]}  
                </small></h4>
              </div>
            </div>
          </NavLink>)}
          </div> : <p>No posts found.</p>
        }
        <div>
          <h3 className="margin-top-20 mega-heading margin-top-90 margin-bottom-40" style={{maxWidth: '900px', fontSize: 36}}>Useful links</h3>
          <ul>
            <li>
              <a href="https://www.bankofengland.co.uk/prudential-regulation" target="_blank" rel="noopener noreferrer">Bank of England - Prudential Regulation</a>
            </li>
            <li>
              <a href="https://www.fca.org.uk/news" target="_blank" rel="noopener noreferrer">Financial Conduct Authority</a>
            </li>
          </ul>
        </div>
        </div>
    </Layout>
  }
}