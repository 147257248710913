import React from "react";
import ReactMarkdown from "react-markdown";
import { NavLink } from 'react-router-dom';
import axios from "../axios";
import Layout from "../Layout";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      slug: "",
      title: "...",
      content: "",
      backgroundImage: null
    };
  }
  componentDidMount() {
    if(this.props.match.params.slug !== this.state.slug) this.getPage();
  }
  componentDidUpdate() {
    this.componentDidMount();
  }
  async getPage() {
    this.setState({slug:this.props.match.params.slug})
    const { data } = await axios.get(
      `pages/?slug=${this.props.match.params.slug}`
    );
    if (data.length) {
      console.log(data[0])
      this.setState({
        title: data[0]["Title"],
        content: data[0]["Content"],
        backgroundImage: data[0]["Banner Image"] ? `https://naissance.gec.group/${data[0]["Banner Image"].url}` : "https://i.imgur.com/r4OKqpB.jpg"
      });
    } else {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <Layout relativeHeader>
        {this.props.location.pathname.indexOf('our-services') > -1 ? <div className="sub-nav">
            <NavLink activeClassName="active" to="/our-services/risk-management">Risk</NavLink>

            <NavLink activeClassName="active" to="/our-services/treasury-and-liquidity">Treasury and Liquidity</NavLink>

            <NavLink activeClassName="active" to="/our-services/operations">Operations</NavLink>

            <NavLink activeClassName="active" to="/our-services/system-implementation">System Implementation</NavLink>

            <NavLink activeClassName="active" to="/our-services/financial-crime">Financial Crime</NavLink>

            <NavLink activeClassName="active" to="/our-services/acquisitions">Acquisitions</NavLink>

            <NavLink activeClassName="active" to="/our-services/corporate-restructuring">Corporate Restructuring</NavLink>

            <NavLink activeClassName="active" to="/our-services/restructuring-banks-financial-institutions">Restructuring Financial Institutions</NavLink>

        </div> : null }
        <div className="page-image-banner" style={{backgroundImage:`url(${this.state.backgroundImage})`}}>
          <div className="header-overlay">
            <h1 className="mega-heading">{this.state.title}</h1>
          </div>
        </div>
        <div className="content-layout padding-80">
          <div className="article">
            <ReactMarkdown html={true} source={this.state.content} />
          </div>
        </div>
      </Layout>
    );
  }
}
