import React, { Component } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import * as Logos from '../../assets/img/clients';

export default class ClientSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      big: true,
      logos: Object.values(Logos)
    }
  }
  componentDidMount() {
    this.checkWidth();
  }
  checkWidth() {
    setInterval(() => {
      console.log(1)
      if (window.innerWidth > 780) {
        this.setState({
          big: true
        });
      } else {
        this.setState({
          big: false
        });
      }
    }, 1000)
  }
  render() {
    return (
      <CarouselProvider
      naturalSlideWidth={100}
      naturalSlideHeight={50}
      interval={2000}
      totalSlides={this.state.logos.length}
      visibleSlides={this.state.big ? 5 : 2}
      isPlaying={true}
      touchEnabled={false}
    >
      <Slider>
        {
          this.state.logos.map((logo, i) => {
            return <Slide index={i} className="xyz" >
              <img alt=" " src={logo} style={{
                justifySelf: 'center',
                alignSelf: 'center',
                maxWidth: "150px",
                maxHeight: "50px"
              }} />
            </Slide>
          })
        }
      </Slider>
    </CarouselProvider>

    )
  }
}
