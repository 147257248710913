import React from "react";
import axios from "../axios";
import ReactMarkdown from "react-markdown";
import Layout from "../Layout";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      job: {}
    };
  }
  componentDidMount() {
    this.getPage();
  }
  componentDidUpdate() {
    // this.componentDidMount();
  }
  async getPage() {
    const { data } = await axios.get(
      `careers/?id=${this.props.match.params.id}`
    );
    if (data.length) {
      this.setState({
        job: data[0]
      });
    } else {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <Layout relativeHeader>
        <div className="page-image-banner" style={{backgroundImage:`url(https://imgur.com/r4OKqpB.jpg)`}}>
          <div className="header-overlay">
            <h1 className="mega-heading">{this.state.job.Role}</h1>
            <h2>{this.state.job.Department}, {this.state.job.Location}</h2>
            <h3>{this.state.job.Salary}</h3>
          </div>
        </div>
        <div className="content-layout padding-80">
          <ReactMarkdown html={true} source={this.state.job.Description} />
        </div>
      </Layout>
    );
  }
}
