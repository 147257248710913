import React from "react";
import Logo from "../../assets/img/logo.svg";

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      invalid: false
    }
  }
  render() {
    return (
      <div className="login">
        <a className="back" href="https://naissance.co.uk">
        <i className="far fa-arrow-left margin-right-5"></i> Return to website
        </a>
        <div>
          <img className="logo" src={Logo} alt="Naissance" />
          <h1 className="margin-top-40">Naissance Client Login</h1>
          <h3 className="margin-top-10 sub-heading">Use the credentials provided to you.</h3>
          <div className="margin-top-40">
            <label>Username / Account e-mail</label><br/>
            <input className="margin-top-10" style={{width:450,maxWidth:"100%"}}/>
          </div>
          <div className="margin-top-30">
            <label>Password</label><br/>
            <input type="password" className="margin-top-10" style={{width:450,maxWidth:"100%"}}/>
          </div>
          {this.state.invalid ? <p style={{fontSize:14, color:"orange"}}>Invalid credentials, please try again.</p> : null}
          <button className="btn margin-top-30" onClick={() => this.setState({invalid:true})}>Login</button>
          <a className="alt-btn margin-left-10 margin-top-30" href="mailto:info@naissance.co.uk">Forgot credentials?</a>
          <br/>
          <p className="margin-top-20" style={{fontSize:14}}>&copy; Copyright {new Date().getFullYear()} Naissance Holdings, All rights reserved.</p>
        </div>
      </div>
    );
  }
}
