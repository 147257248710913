import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga";

import "./assets/scss/App.scss";

import Routes from "./js/Routes";

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize("UA-146205394-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <div className="container">
        <div className="container-center">
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </div>
      </div>
    );
  }
}

export default App;
