import React from 'react';
import axios from '../axios';
import Layout from '../Layout';

export default class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [],
      query: new URLSearchParams(this.props.location.search).get("query"),
      input: ""
    }
  }
  componentDidMount() {
    this.setState({
      posts: []
    })
    if(this.state.query) {
      this.setState({input:this.state.query}, this.search);
    }
  }
  search() {
    let newsPromises = [...this.searchNews(this.state.query)];

    Promise.all(newsPromises).then(res => {
      res.forEach(item => {
        if(item.data.length){
        if (this.state.posts.filter(e => e.id === item.data[0].id).length >= 1) {
          return;
        } else {
          let newArr = this.state.posts;
          newArr = newArr.concat(item.data);
          this.setState({
            posts: newArr
          });
        }
      }
      })
    })
  }
  searchNews(query) {
    return [
      this.searchNewsTitle(query),
      this.searchNewsBody(query)
    ]
  }
  searchNewsTitle(query) {
    return axios.get(`posts?title_contains=${query}&_limit=5`);
  }
  searchNewsBody(query) {
    return axios.get(`posts?content_contains=${query}&_limit=5`);
  }
  componentWillReceiveProps() {
    this.componentDidMount();
  }
  reload() {
    this.props.history.push(`?query=${this.state.input}`);
    window.location.reload();
  }
  render() {
    return (
      <Layout relativeHeader>
        <div className="page-banner">
          <h1 className="mega-heading">{this.state.query ? `Searching for "${this.state.query}"` : "Search Our Website"}</h1>
          <div className="search">
            <input type="text" placeholder="Search..." maxLength={40} onKeyDown={e =>
                        e.key === "Enter" ? this.reload() : null
                      } onChange={e => this.setState({input:e.currentTarget.value.toLowerCase()})}/>
            <i className="far fa-search" onClick={this.reload}></i>
          </div>
        </div>
        <div className="content-layout padding-80">
          {this.state.posts.length ? <div>
           <h2 className="padding-bottom-20">Posts</h2>
            {
              this.state.posts.map(e => {
                return (<div>
                  <h3><strong>{e.Title}</strong></h3>
                  <p>{e.Content.length >= 500 ? e.Content.substr(0, 500) + '...' : e.Content}</p>
                  <a href={`/news/${e.Slug}`}><button className="btn">Read more</button></a>
                  <hr className="margin-top-20 margin-bottom-20" style={{border: '1px solid #eeeeee'}} />
                </div>)
              })
            }
          </div> : this.state.query ? 'Nothing found matching your search.' : ''}
        </div>
      </Layout>
    )
  }
}
