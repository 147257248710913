import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout';

export default class extends React.Component {
  render(){
    return <Layout relativeHeader>
      <div className="page-image-banner" style={{backgroundImage:"url(https://i.imgur.com/vd9S5ic.jpg)"}}>
        <div className="header-overlay">
          <h1 className="mega-heading">Our Services</h1>
          <h2 className="sub-heading margin-top-10">Together, we are Naissance.</h2>
          </div></div>
      <div className="grid padding-60">
      <div className="grid col-2 gap-30 services-card-container">
        <Link to="/our-services/risk-management" className="services-card">
          <i className="fal fa-exclamation-triangle"></i>
          <h2>Risk Management</h2>
        </Link>
        <Link to="/our-services/treasury-and-liquidity" className="services-card">
          <i className="fal fa-pound-sign"></i>
          <h2>Treasury and Liquidity</h2>
        </Link>
        <Link to="/our-services/operations" className="services-card">
          <i className="fal fa-cogs"></i>
          <h2>Operations</h2>
        </Link>
        <Link to="/our-services/system-implementation" className="services-card">
          <i className="fal fa-window-maximize"></i>
          <h2>System Implementation</h2>
        </Link>
        <Link to="/our-services/financial-crime" className="services-card">
          <i className="fal fa-do-not-enter"></i>
          <h2>Financial Crime</h2>
        </Link>
        <Link to="/our-services/acquisitions" className="services-card">
          <i className="fal fa-handshake"></i>
          <h2>Acquisitions</h2>
        </Link>
        <Link to="/our-services/corporate-restructuring" className="services-card">
          <i className="fal fa-users"></i>
          <h2>Corporate Restructuring</h2>
        </Link>
        <Link to="/our-services/restructuring-banks-financial-institutions" className="services-card">
          <i className="fal fa-university"></i>
          <h2>Restructuring of Banks and Financial Institutions</h2>
        </Link>
      </div>
      </div>
    </Layout>
  }
}