import React from 'react';
import Layout from '../Layout';
import axios from '../axios';
import { NavLink } from 'react-router-dom';

export default class extends React.Component {
  constructor(){
    super();
    this.state = {
      jobs: []
    }
  }
  componentDidMount(){
    this.getJobs();
  }
  async getJobs(){
    const { data } = await axios.get("careers");
    this.setState({
      jobs: data
    });
  }
  render(){
    return <Layout relativeHeader>
      <div className="page-banner">
          <h1 className="mega-heading">Careers at Naissance</h1>
          <h2 className="sub-heading margin-top-10">Our current openings.</h2>
      </div>
      <div className="content-layout padding-80">
        {
          this.state.jobs.length ? <div className="col-1 gap-30"> 
          {this.state.jobs.map(x => <NavLink className="post-preview grid gap-5" to={`/careers/${x.id}`}>
            <h3>{x.Location}</h3>
            <h2>{x.Role}</h2>
            <p className="padding-0 margin-0">{x.Prelude}</p>
          </NavLink>)}
          </div> : <p>No openings found at this time.</p>
        }
      </div>
    </Layout>
  }
}